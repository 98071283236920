
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "enter-job-survey-widget",
  emits: ["entry-type-change"],
  setup(props, { emit }) {
    const entryType = ref<string>();

    function onEntryClick(value) {
      emit("entry-type-change", value);
    }

    return { entryType, onEntryClick };
  },
});
