<template>
  <div class="card">
    <!--begin::Body-->
    <div class="card-body p-5 px-lg-19 py-lg-16">
      <!--begin::Content main-->
      <div class="mb-14">
        <!--begin::Heading-->
        <div class="mb-15">
          <!--begin::Title-->
          <h1 class="fs-2x mb-6 incline-made_tommy_softbold">
            JOB Survey Entry
          </h1>
          <!--end::Title-->
          <!--begin::Text-->
          <div class="fs-5 text-gray-600 fw-bold">
            Here you can enter survey data that you obtained either from an
            existing survey or a completed survey.
          </div>
          <!--end::Text-->
        </div>
        <!--end::Heading-->
        <!--begin::Body-->
        <!--begin::Block-->
        <div class="mb-20 pb-lg-20">
          <!--begin::Title-->
          <h2 class="mb-8 incline-made_tommy_softmedium">
            You have two options to enter survey data:
          </h2>
          <!--end::Title-->
          <div class="fv-row fv-plugins-icon-container">
            <!--begin::Row-->
            <div class="row">
              <!--begin::Col-->
              <div class="col-lg-6">
                <!--begin::Option-->
                <input
                  type="radio"
                  class="btn-check"
                  name="account_type"
                  value="existingdata"
                  id="kt_create_account_form_account_type_personal"
                  data-bs-toggle="modal"
                  data-bs-target="#incline_modal_enter_job_existingdata"
                  @click="onEntryClick('existingdata')"
                />
                <label
                  class="
                    btn btn-outline btn-outline-dashed btn-outline-default
                    p-7
                    d-flex
                    align-items-center
                    mb-10
                  "
                  for="kt_create_account_form_account_type_personal"
                >
                  <!--begin::Svg Icon | path: icons/duotune/communication/com005.svg-->
                  <span class="svg-icon svg-icon-3x me-2">
                    <img
                      src="/media/icons/duotune/files/fil004.svg"
                      class="h-40px me-4"
                    />
                  </span>
                  <!--end::Svg Icon-->
                  <!--begin::Info-->
                  <span class="d-block fw-bold text-start">
                    <span class="text-dark fw-bolder d-block fs-4 mb-2"
                      >Enter Existing Data By Trait</span
                    >
                    <span class="text-muted fw-bold fs-6"
                      >The respondent has taken a survey before and provided
                      results.</span
                    >
                  </span>
                  <!--end::Info-->
                </label>
                <!--end::Option-->
              </div>
              <!--end::Col-->
              <!--begin::Col-->
              <div class="col-lg-6">
                <!--begin::Option-->
                <input
                  type="radio"
                  class="btn-check"
                  name="account_type"
                  value="printVersion"
                  id="kt_enter_by_printed_version"
                  data-bs-toggle="modal"
                  data-bs-target="#modal_enter_by_printed_version"
                  @click="onEntryClick('manualentry')"
                />
                <label
                  class="
                    btn btn-outline btn-outline-dashed btn-outline-default
                    p-7
                    d-flex
                    align-items-center
                  "
                  for="kt_enter_by_printed_version"
                >
                  <!--begin::Svg Icon | path: icons/duotune/finance/fin006.svg-->
                  <span class="svg-icon svg-icon-3x me-2">
                    <img
                      src="/media/icons/duotune/files/fil003.svg"
                      class="h-40px me-4"
                    />
                  </span>
                  <!--end::Svg Icon-->
                  <!--begin::Info-->
                  <span class="d-block fw-bold text-start">
                    <span class="text-dark fw-bolder d-block fs-4 mb-2"
                      >Enter Survey from Printed Version</span
                    >
                    <span class="text-muted fw-bold fs-6"
                      >The respondent completed the surveys from a printed
                      version.</span
                    >
                  </span>
                  <!--end::Info-->
                </label>
                <!--end::Option-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
          </div>
        </div>
        <!--end::Block-->
        <!--end::Body-->
      </div>
      <!--end::Content main-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "enter-job-survey-widget",
  emits: ["entry-type-change"],
  setup(props, { emit }) {
    const entryType = ref<string>();

    function onEntryClick(value) {
      emit("entry-type-change", value);
    }

    return { entryType, onEntryClick };
  },
});
</script>
