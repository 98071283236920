
import { defineComponent, computed, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { JobPhraseLineItem } from "../../core/types/JOBTypes";

interface PhraseListData {
  surveyType: string;
  checkedPhrases: JobPhraseLineItem[];
}

export default defineComponent({
  name: "job-survey-phrase-list",
  props: ["surveyType"],
  components: { TableSkeleton },
  emits: ["list-change"],
  setup(props, { emit }) {
    const store = useStore();
    const isDataReady = ref(false);
    const phrases = computed(() => {
      return store.getters.getJobPhraseList;
    });
    //const checkedWords = ref([] as BehavioralWordLineItem[]);
    const phraseListData = ref({
      surveyType: props.surveyType,
      checkedPhrases: [],
    } as PhraseListData);
    store
      .dispatch(Actions.GET_JOB_PHRASELIST_ITEMS)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText:
            "There was a problem retrieving the list of phrases.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const listChange = () => {
      emit("list-change", phraseListData.value);
    };

    watch(phraseListData.value, () => {
      listChange();
    });

    watch(phrases, () => {
      isDataReady.value = true;
    });

    return { isDataReady, phrases, phraseListData, listChange };
  },
});
