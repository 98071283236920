
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  toRaw,
} from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import { Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { EmailLookup } from "../../../core/types/LookupTypes";
import { JobPhraseLineItem } from "../../../core/types/JOBTypes";

interface Step1 {
  currentAspNetUserId: string;
  respondentAspNetUserId: string;
  emailSurveyQueId: number;
  userExists: boolean;
  surveyEntryType: string;
  languageVersion: string;
  surveyJobTitle: string;
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  licensedDistributorId: number;
  clientId: number;
  folderId: number;
  personalFolderId: number;
}

interface Step2 {
  autonomyTotal: number;
  socialTotal: number;
  patienceTotal: number;
  certaintyTotal: number;
  psychicEnergyTotal: number;
  jobPhraseLineItems: JobPhraseLineItem[];
}

interface DropDownSetup {
  selectedLicensedDistributor: number;
  selectedClient: number;
  selectedFolder: number;
  selectedPersonalFolder: number;
}

interface KTCreateApp extends Step1, Step2 {}

export default defineComponent({
  name: "incline-enter-job-data-by-traits",
  props: [
    "entryType",
    "userRole",
    "licensedDistributorId",
    "licensedDistAssocId",
    "clientId",
    "folderId",
  ],
  components: {
    Field,
    ErrorMessage,
  },
  setup(props) {
    const store = useStore();
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    const _stepperObj = ref<StepperComponent | null>(null);
    const createAccountRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const modalRef = ref<null | HTMLFormElement>(null);
    const emailLookupResponse = ref<EmailLookup>({
      aspNetUserId: "",
      firstName: "",
      lastName: "",
      email: "",
      jobTitle: "",
      userExists: false,
    });

    const initialDropDownSetup: DropDownSetup = {
      selectedLicensedDistributor: 0,
      selectedClient: 0,
      selectedFolder: 0,
      selectedPersonalFolder: 0,
    };

    const dropDownSetup = reactive<DropDownSetup>({ ...initialDropDownSetup });
    const languageVersion = ref("english");

    const formData = ref<KTCreateApp>({
      currentAspNetUserId: currentUser.value.id,
      respondentAspNetUserId: "",
      emailSurveyQueId: 0,
      userExists: false,
      surveyEntryType: "",
      languageVersion: "",
      surveyJobTitle: "",
      firstName: "",
      lastName: "",
      email: "",
      jobTitle: "",
      licensedDistributorId: 0,
      clientId: 0,
      folderId: 0,
      personalFolderId: 0,
      autonomyTotal: 0,
      socialTotal: 0,
      patienceTotal: 0,
      certaintyTotal: 0,
      psychicEnergyTotal: 0,
      jobPhraseLineItems: [],
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        createAccountRef.value as HTMLElement
      );

      if (props.userRole === "SuperAdmin") {
        store
          .dispatch(Actions.GET_LICENSED_DISTRIBUTOR_DDL)
          .then(() => {
            //
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText:
                "There was a problem retrieving licensed distributors.",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      } else if (
        props.userRole === "LicensedDistributor" ||
        props.userRole === "LDAssociate"
      ) {
        getClients();
      } else if (
        props.userRole === "PowerUser" ||
        props.userRole === "ClientUser"
      ) {
        getClientFolders();
      }
    });

    const licensedDistributors = computed(() => {
      return store.getters.getLicensedDistributorDDL;
    });

    const clientList = computed(() => {
      return store.getters.getClientDDL;
    });

    const clientFolderList = computed(() => {
      return store.getters.getClientFoldersDDL;
    });

    const personaFolderList = computed(() => {
      return store.getters.getPersonalFoldersDDL;
    });

    store
      .dispatch(Actions.GET_PERSONAL_FOLDERS_DDL, currentUser.value.id)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving personal folders.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const getClients = () => {
      if (props.userRole === "LicensedDistributor") {
        dropDownSetup.selectedLicensedDistributor = props.licensedDistributorId;
      } else if (props.userRole === "LDAssociate") {
        dropDownSetup.selectedLicensedDistributor = props.licensedDistAssocId;
      }

      store
        .dispatch(
          Actions.GET_CLIENTS_DDL,
          toRaw(dropDownSetup.selectedLicensedDistributor)
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem retrieving clients.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const getClientFolders = () => {
      if (props.userRole === "PowerUser" || props.userRole === "ClientUser") {
        dropDownSetup.selectedClient = props.clientId;
      }

      store
        .dispatch(
          Actions.GET_CLIENT_JOB_FOLDERS_DDL,
          toRaw(dropDownSetup.selectedClient)
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem retrieving client folders.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const createAppSchema = [
      Yup.object({
        surveyJobTitle: Yup.string().required().label("Job Title"),
        firstName: Yup.string().required().label("First Name"),
        lastName: Yup.string().required().label("Last Name"),
        email: Yup.string().required().email().label("Email"),
        jobTitle: Yup.string().required().label("Job Title"),
      }),
      Yup.object({}),
    ];

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createAppSchema[currentStepIndex.value];
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2>({
      validationSchema: currentSchema,
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const handleStep = handleSubmit((values) => {
      for (const item in values) {
        // eslint-disable-next-line no-prototype-builtins
        if (values.hasOwnProperty(item)) {
          if (values[item]) {
            formData.value[item] = values[item];
          }
        }
      }

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const formSubmit = () => {
      formData.value.surveyEntryType = props.entryType;
      formData.value.languageVersion = languageVersion.value;
      formData.value.folderId = dropDownSetup.selectedFolder;

      if (props.userRole === "PowerUser" || props.userRole === "ClientUser") {
        formData.value.clientId = props.clientId;
      } else {
        formData.value.licensedDistributorId =
          dropDownSetup.selectedLicensedDistributor;
        formData.value.clientId = dropDownSetup.selectedClient;
        formData.value.personalFolderId = dropDownSetup.selectedPersonalFolder;
      }

      if (
        formData.value.folderId === 0 &&
        formData.value.personalFolderId === 0
      ) {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "You must select a folder type.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else {
        store
          .dispatch(Actions.CREATE_JOB_SURVEY, formData.value)
          .then(() => {
            Swal.fire({
              text: "Success. The survey has been created.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Continue",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              resetForm();
              modalRef.value?.click();
              window.location.reload();
            });
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
    };

    resetForm({
      values: {
        ...formData.value,
      },
    });

    function folderChange(val) {
      if (val == "client") {
        dropDownSetup.selectedPersonalFolder = 0;
      } else {
        dropDownSetup.selectedFolder = 0;
      }
    }

    function lookupEmail() {
      store
        .dispatch(Actions.LOOKUP_EMAIL, emailLookupResponse.value)
        .then(() => {
          emailLookupResponse.value = store.getters.getEmailLookupResponse;
          formData.value.email = emailLookupResponse.value.email;

          onEmailLookup();
        });
    }

    function onEmailLookup() {
      if (emailLookupResponse.value.userExists) {
        Swal.fire({
          title: "Duplicate names may exist.",
          html:
            "<b>" +
            emailLookupResponse.value.firstName +
            " " +
            emailLookupResponse.value.lastName +
            "</b><br />" +
            emailLookupResponse.value.email +
            "<br /><br />If this information is correct, click Yes.",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.isConfirmed) {
            formData.value.firstName = emailLookupResponse.value.firstName;
            formData.value.lastName = emailLookupResponse.value.lastName;
            formData.value.jobTitle = emailLookupResponse.value.jobTitle;
            formData.value.respondentAspNetUserId =
              emailLookupResponse.value.aspNetUserId;
            formData.value.userExists = true;
          }
        });
      }
    }

    async function reset() {
      Object.assign(dropDownSetup, initialDropDownSetup);
      resetForm();
    }

    return {
      currentUser,
      createAccountRef,
      totalSteps,
      previousStep,
      handleStep,
      modalRef,
      languageVersion,
      folderChange,
      lookupEmail,
      onEmailLookup,
      emailLookupResponse,
      formSubmit,
      getClients,
      getClientFolders,
      licensedDistributors,
      currentStepIndex,
      formData,
      clientList,
      personaFolderList,
      clientFolderList,
      ...toRefs(dropDownSetup),
      reset,
    };
  },
});
