<template>
  <div class="mt-2">
    <p
      style="
        font-size: 16px;
        margin-bottom: 0px;
        margin-top: 10px;
        font-style: italic;
      "
    >
      <strong>Instructions:</strong>
    </p>
    <p style="font-size: 16px; margin-bottom: 20px; font-style: italic">
      Take as much time as you need and check the boxes next to phrases that
      will be an important and/or frequent aspect of this job. Another way to
      think of this would be ask yourself, "If I were to write a job description
      about this job, would I include this phrase?"
    </p>
    <div v-if="isDataReady">
      <ul
        style="
          -webkit-column-count: 2;
          -moz-column-count: 2;
          column-count: 2;
          list-style-type: none;
          padding-left: 0px;
        "
      >
        <li
          v-for="(phrase, index) in phrases"
          :key="phrase.jobPhraseId"
          :id="'phrase_' + index"
        >
          <div class="form-check form-check-custom form-check-solid mx-3 mb-5">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="phraseListData.checkedPhrases"
              :value="phrase"
              :id="'flexCheckDefault' + index"
            />
            <label class="form-check-label" :for="'flexCheckDefault' + index">{{
              phrase.phrase
            }}</label>
          </div>
        </li>
      </ul>
    </div>

    <div v-else>
      <TableSkeleton></TableSkeleton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { JobPhraseLineItem } from "../../core/types/JOBTypes";

interface PhraseListData {
  surveyType: string;
  checkedPhrases: JobPhraseLineItem[];
}

export default defineComponent({
  name: "job-survey-phrase-list",
  props: ["surveyType"],
  components: { TableSkeleton },
  emits: ["list-change"],
  setup(props, { emit }) {
    const store = useStore();
    const isDataReady = ref(false);
    const phrases = computed(() => {
      return store.getters.getJobPhraseList;
    });
    //const checkedWords = ref([] as BehavioralWordLineItem[]);
    const phraseListData = ref({
      surveyType: props.surveyType,
      checkedPhrases: [],
    } as PhraseListData);
    store
      .dispatch(Actions.GET_JOB_PHRASELIST_ITEMS)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText:
            "There was a problem retrieving the list of phrases.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const listChange = () => {
      emit("list-change", phraseListData.value);
    };

    watch(phraseListData.value, () => {
      listChange();
    });

    watch(phrases, () => {
      isDataReady.value = true;
    });

    return { isDataReady, phrases, phraseListData, listChange };
  },
});
</script>
