<template>
  <!--begin::Modal - Create account-->
  <div
    class="modal fade"
    id="incline_modal_enter_job_existingdata"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog mw-1000px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header">
          <!--begin::Title-->
          <h2>Enter Existing JOB Data By Trait</h2>
          <!--end::Title-->

          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            ref="modalRef"
            @click="reset"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y m-5">
          <!--begin::Stepper-->
          <div
            ref="createAccountRef"
            class="stepper stepper-links d-flex flex-column"
            id="kt_create_account_stepper"
          >
            <!--begin::Nav-->
            <div class="stepper-nav py-5">
              <!--begin::Step 1-->
              <div class="stepper-item current" data-kt-stepper-element="nav">
                <h3 class="stepper-title">Respondent/Job Info</h3>
              </div>
              <!--end::Step 1-->

              <!--begin::Step 3-->
              <div class="stepper-item" data-kt-stepper-element="nav">
                <h3 class="stepper-title">Trait Data</h3>
              </div>
              <!--end::Step 3-->

              <!--begin::Step 4-->
              <div class="stepper-item" data-kt-stepper-element="nav">
                <h3 class="stepper-title">Review</h3>
              </div>
              <!--end::Step 4-->
            </div>
            <!--end::Nav-->

            <!--begin::Form-->
            <form
              class="mx-auto mw-600px w-100 py-10"
              novalidate="novalidate"
              id="kt_create_account_form"
              @submit="handleStep"
            >
              <!--begin::Step 2-->
              <div class="current" data-kt-stepper-element="content">
                <!--begin::Wrapper-->
                <div class="w-100">
                  <!--begin::Heading-->
                  <div class="pb-10 pb-lg-12">
                    <!--begin::Title-->
                    <h2 class="fw-bolder text-dark">Respondent Info</h2>
                    <!--end::Title-->
                  </div>
                  <!--end::Heading-->
                  <!--begin::Input group-->
                  <div class="fv-row mb-7">
                    <!--begin::Label-->
                    <label class="form-label required">Survey Job Title</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      name="surveyJobTitle"
                      class="form-control form-control-lg form-control-solid"
                      value=""
                      v-model="formData.surveyJobTitle"
                    />
                    <ErrorMessage
                      class="fv-plugins-message-container invalid-feedback"
                      name="surveyJobTitle"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="fv-row mb-7">
                    <!--begin::Label-->
                    <label class="fs-6 fw-bold form-label required"
                      >Email</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      name="email"
                      class="form-control form-control-lg form-control-solid"
                      value=""
                      v-model="emailLookupResponse.email"
                      @change="lookupEmail()"
                    />
                    <ErrorMessage
                      class="fv-plugins-message-container invalid-feedback"
                      name="email"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="fv-row mb-7">
                    <!--begin::Label-->
                    <label class="form-label required">First Name</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      name="firstName"
                      class="form-control form-control-lg form-control-solid"
                      value=""
                      v-model="formData.firstName"
                    />
                    <ErrorMessage
                      class="fv-plugins-message-container invalid-feedback"
                      name="firstName"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->

                  <!--begin::Input group-->
                  <div class="fv-row mb-7">
                    <!--begin::Label-->
                    <label class="d-flex align-items-center form-label">
                      <span class="required">Last Name</span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      name="lastName"
                      class="form-control form-control-lg form-control-solid"
                      value=""
                      v-model="formData.lastName"
                    />
                    <ErrorMessage
                      class="fv-plugins-message-container invalid-feedback"
                      name="lastName"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="fv-row mb-7">
                    <!--begin::Label-->
                    <label class="d-flex align-items-center form-label">
                      <span class="required">Respondent Job Title</span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      name="jobTitle"
                      class="form-control form-control-lg form-control-solid"
                      value=""
                      v-model="formData.jobTitle"
                    />
                    <ErrorMessage
                      class="fv-plugins-message-container invalid-feedback"
                      name="jobTitle"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin:Tabs -->
                  <ul class="nav nav-tabs nav-line-tabs mb-5 fs-5">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-bs-toggle="tab"
                        href="#client_tab_pane_1"
                        @click="folderChange('client')"
                        >Select Location Folder</a
                      >
                    </li>
                    <li
                      class="nav-item"
                      v-if="
                        userRole === 'SuperAdmin' ||
                        userRole === 'LicensedDistributor' ||
                        userRole === 'LDAssociate'
                      "
                    >
                      <a
                        class="nav-link"
                        data-bs-toggle="tab"
                        href="#personal_tab_pane_2"
                        @click="folderChange('personal')"
                        >Select Personal Folder</a
                      >
                    </li>
                  </ul>
                  <div class="tab-content" id="clientTabContent">
                    <div
                      class="tab-pane fade active show"
                      id="client_tab_pane_1"
                      role="tabpanel"
                    >
                      <!--begin::Input group-->
                      <div class="fv-row">
                        <!--begin:: Licensed Distributor Dropdown-->
                        <div
                          v-if="userRole === 'SuperAdmin'"
                          class="col-md-6 fv-row mb-7"
                        >
                          <label
                            for="licensedDistributor"
                            class="fs-6 fw-bold mb-2"
                            >Licensed Distributor</label
                          >

                          <select
                            name="licensedDistributor"
                            class="form-select form-select-solid"
                            as="select"
                            v-model="selectedLicensedDistributor"
                            @change="getClients()"
                          >
                            <option value="0">Select a Distributor...</option>
                            <option
                              v-for="item in licensedDistributors"
                              :key="item.licensedDistributorId"
                              :label="item.name"
                              :value="item.licensedDistributorId"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                        <!--end:: Licensed Distributo Dropdown-->
                        <!--begin:: Client Dropdown-->
                        <div
                          class="col-md-6 fv-row mb-7"
                          v-if="
                            userRole === 'SuperAdmin' ||
                            userRole === 'LicensedDistributor' ||
                            userRole === 'LDAssociate'
                          "
                        >
                          <label class="fs-6 fw-bold mb-2">Client</label>

                          <Field
                            name="client"
                            class="form-select form-select-solid"
                            as="select"
                            v-model="selectedClient"
                            @change="getClientFolders()"
                          >
                            <option value="0">Select a Client...</option>
                            <option
                              v-for="item in clientList"
                              :key="item.clientId"
                              :label="item.name"
                              :value="item.clientId"
                            >
                              {{ item.name }}
                            </option>
                          </Field>
                        </div>
                        <!--end:: Client Dropdown-->
                        <!--begin:: Client Folder Dropdown-->
                        <div class="col-md-6 fv-row mb-7">
                          <label class="fs-6 fw-bold mb-2">Folders</label>

                          <Field
                            name="folder"
                            class="form-select form-select-solid"
                            as="select"
                            v-model="selectedFolder"
                          >
                            <option value="0">Select a Folder...</option>
                            <option
                              v-for="item in clientFolderList"
                              :key="item.folderId"
                              :label="item.folderName"
                              :value="item.folderId"
                            >
                              {{ item.folderName }}
                            </option>
                          </Field>
                        </div>
                        <!--end:: Client Dropdown-->
                      </div>
                      <!--end::Input group-->
                    </div>
                    <div
                      class="tab-pane fade"
                      id="personal_tab_pane_2"
                      role="tabpanel"
                      v-if="
                        userRole === 'SuperAdmin' ||
                        userRole === 'LicensedDistributor' ||
                        userRole === 'LDAssociate'
                      "
                    >
                      <!--begin:: Personal Folder Dropdown-->
                      <div class="col-md-6 fv-row mb-7">
                        <label class="fs-6 fw-bold mb-2">Folders</label>

                        <Field
                          name="folder"
                          class="form-select form-select-solid"
                          as="select"
                          v-model="selectedPersonalFolder"
                        >
                          <option value="0">Select a Folder...</option>
                          <option
                            v-for="item in personaFolderList"
                            :key="item.folderId"
                            :label="item.folderName"
                            :value="item.folderId"
                          >
                            {{ item.folderName }}
                          </option>
                        </Field>
                      </div>
                      <!--end:: Personal Fodler Dropdown-->
                    </div>
                  </div>
                  <!--end::Tabs-->
                </div>
                <!--end::Wrapper-->
              </div>
              <!--end::Step 2-->

              <!--begin::Step 3-->
              <div data-kt-stepper-element="content">
                <!--begin::Wrapper-->
                <div class="w-100">
                  <!--begin::Heading-->
                  <div class="pb-10 pb-lg-12">
                    <!--begin::Title-->
                    <h2 class="fw-bolder text-dark">Trait Data</h2>
                    <!--end::Title-->
                  </div>
                  <!--end::Heading-->
                  <!--begin::Input group-->
                  <div class="row g-9 mb-4">
                    <!--begin::Col-->
                    <div class="col-md-12 fv-row">
                      <div class="row">
                        <!--begin::Col-->
                        <div class="col-md-6 fv-row mb-8">
                          <label
                            style="display: inline-flex"
                            class="form-label required fs-6 fw-bold mb-2"
                            ><span
                              class="
                                incline-trait-circle incline-primary-red-bkgrd
                              "
                              style="margin-right: 5px"
                              >A</span
                            >Autonomy</label
                          >

                          <Field
                            name="autonomy"
                            class="
                              form-control form-control-lg form-control-solid
                            "
                            type="number"
                            placeholder="autonomy"
                            v-model="formData.autonomyTotal"
                          />
                          <ErrorMessage
                            class="
                              fv-plugins-message-container
                              invalid-feedback
                            "
                            name="nautalautonomy"
                          />
                        </div>
                        <!--end::Col-->
                        <!--begin::Col-->
                        <div class="col-md-6 fv-row mb-8">
                          <label
                            style="display: inline-flex"
                            class="required fs-6 fw-bold mb-2"
                            ><span
                              class="
                                incline-trait-circle
                                incline-primary-yellow-bkgrd
                              "
                              style="margin-right: 5px"
                              >S</span
                            >Social</label
                          >

                          <Field
                            class="
                              form-control form-control-lg form-control-solid
                            "
                            type="number"
                            placeholder="social"
                            name="naturalsocial"
                            autocomplete="off"
                            v-model="formData.socialTotal"
                          />
                        </div>
                        <!--end::Col-->
                        <!--begin::Col-->
                        <div class="col-md-6 fv-row mb-8">
                          <label
                            style="display: inline-flex"
                            class="required fs-6 fw-bold mb-2"
                            ><span
                              class="
                                incline-trait-circle incline-primary-green-bkgrd
                              "
                              style="margin-right: 5px"
                              >P</span
                            >Patience</label
                          >

                          <Field
                            class="
                              form-control form-control-lg form-control-solid
                            "
                            type="number"
                            placeholder="patience"
                            name="naturalpatience"
                            autocomplete="off"
                            v-model="formData.patienceTotal"
                          />
                        </div>
                        <!--end::Col-->
                        <!--begin::Col-->
                        <div class="col-md-6 fv-row mb-8">
                          <label
                            style="display: inline-flex"
                            class="required fs-6 fw-bold mb-2"
                            ><span
                              class="
                                incline-trait-circle incline-primary-blue-bkgrd
                              "
                              style="margin-right: 5px"
                              >C</span
                            >Certainty</label
                          >

                          <Field
                            class="
                              form-control form-control-lg form-control-solid
                            "
                            type="number"
                            placeholder="certainty"
                            name="naturalcertainty"
                            autocomplete="off"
                            v-model="formData.certaintyTotal"
                          />
                        </div>
                        <!--end::Col-->
                        <!--begin::Col-->
                        <div class="col-md-6 fv-row mb-8">
                          <label
                            style="display: inline-flex"
                            class="required fs-6 fw-bold mb-2"
                            ><span
                              class="incline-trait-circle incline-purple-bkgrd"
                              style="margin-right: 5px"
                              >PE</span
                            >Psychic Energy</label
                          >

                          <Field
                            class="
                              form-control form-control-lg form-control-solid
                            "
                            type="number"
                            placeholder="psychicEnergy"
                            name="naturalpsychicEnergy"
                            autocomplete="off"
                            v-model="formData.psychicEnergyTotal"
                          />
                        </div>
                        <!--end::Col-->
                      </div>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                </div>
                <!--end::Wrapper-->
              </div>
              <!--end::Step 3-->

              <!--begin::Step 4-->
              <div data-kt-stepper-element="content">
                <!--begin::Wrapper-->
                <div class="w-100">
                  <!--begin::Heading-->
                  <div class="pb-8 pb-lg-10">
                    <!--begin::Title-->
                    <h2 class="fw-bolder text-dark">
                      Review your Information!
                    </h2>
                    <!--end::Title-->
                  </div>
                  <!--end::Heading-->

                  <!--begin::Body-->
                  <div class="mb-0">
                    <!--begin::Text-->
                    <div class="fs-6 text-gray-600 mb-5">
                      Before submitting the survey data review the information
                      below for validity.
                    </div>
                    <!--end::Text-->

                    <!--begin::Alert-->
                    <div
                      class="
                        notice
                        d-flex
                        flex-row
                        bg-light-warning
                        rounded
                        border-warning border border-dashed
                        p-6
                      "
                    >
                      <div class="d-flex flex-column flex-row-fluid">
                        <div>
                          <h4 class="text-gray-800 fw-bolder">
                            Job Title: {{ formData.surveyJobTitle }}
                          </h4>
                        </div>
                        <div>
                          <h4 class="text-gray-800 fw-bolder">
                            {{ formData.firstName }} {{ formData.lastName
                            }}<br /><small>{{ formData.email }}</small>
                          </h4>
                        </div>
                        <div class="d-flex flex-row flex-column-fluid">
                          <div class="d-flex flex-row-fluid flex-start">
                            <div class="fs-6 text-gray-600">
                              <strong>Trait Data</strong><br />
                              Autonomy: {{ formData.autonomyTotal }}
                              <br />
                              Social: {{ formData.socialTotal }}
                              <br />
                              Patience: {{ formData.patienceTotal }}
                              <br />
                              Certainty:
                              {{ formData.certaintyTotal }}
                              <br />
                              Psychic Energy:
                              {{ formData.psychicEnergyTotal }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--end::Alert-->
                  </div>
                  <!--end::Body-->
                </div>
                <!--end::Wrapper-->
              </div>
              <!--end::Step 4-->

              <!--begin::Actions-->
              <div class="d-flex flex-stack pt-15">
                <!--begin::Wrapper-->
                <div class="me-2">
                  <button
                    type="button"
                    class="btn btn-lg btn-light-primary me-3"
                    data-kt-stepper-action="previous"
                    @click="previousStep()"
                  >
                    <span class="svg-icon svg-icon-3 me-1">
                      <inline-svg src="media/icons/duotune/arrows/arr063.svg" />
                    </span>
                    Back
                  </button>
                </div>
                <!--end::Wrapper-->

                <!--begin::Wrapper-->
                <div>
                  <button
                    type="submit"
                    class="btn btn-lg btn-primary"
                    v-if="currentStepIndex === totalSteps - 1"
                    @click="formSubmit()"
                  >
                    <span class="indicator-label">
                      Submit
                      <span class="svg-icon svg-icon-3 ms-2 me-0">
                        <inline-svg src="icons/duotune/arrows/arr064.svg" />
                      </span>
                    </span>
                    <span class="indicator-progress">
                      Please wait...
                      <span
                        class="
                          spinner-border spinner-border-sm
                          align-middle
                          ms-2
                        "
                      ></span>
                    </span>
                  </button>

                  <button type="submit" class="btn btn-lg btn-primary" v-else>
                    Continue
                    <span class="svg-icon svg-icon-3 ms-1 me-0">
                      <inline-svg src="media/icons/duotune/arrows/arr064.svg" />
                    </span>
                  </button>
                </div>
                <!--end::Wrapper-->
              </div>
              <!--end::Actions-->
            </form>
            <!--end::Form-->
          </div>
          <!--end::Stepper-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - Create project-->
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  toRaw,
} from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import { Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { EmailLookup } from "../../../core/types/LookupTypes";
import { JobPhraseLineItem } from "../../../core/types/JOBTypes";

interface Step1 {
  currentAspNetUserId: string;
  respondentAspNetUserId: string;
  emailSurveyQueId: number;
  userExists: boolean;
  surveyEntryType: string;
  languageVersion: string;
  surveyJobTitle: string;
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  licensedDistributorId: number;
  clientId: number;
  folderId: number;
  personalFolderId: number;
}

interface Step2 {
  autonomyTotal: number;
  socialTotal: number;
  patienceTotal: number;
  certaintyTotal: number;
  psychicEnergyTotal: number;
  jobPhraseLineItems: JobPhraseLineItem[];
}

interface DropDownSetup {
  selectedLicensedDistributor: number;
  selectedClient: number;
  selectedFolder: number;
  selectedPersonalFolder: number;
}

interface KTCreateApp extends Step1, Step2 {}

export default defineComponent({
  name: "incline-enter-job-data-by-traits",
  props: [
    "entryType",
    "userRole",
    "licensedDistributorId",
    "licensedDistAssocId",
    "clientId",
    "folderId",
  ],
  components: {
    Field,
    ErrorMessage,
  },
  setup(props) {
    const store = useStore();
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    const _stepperObj = ref<StepperComponent | null>(null);
    const createAccountRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const modalRef = ref<null | HTMLFormElement>(null);
    const emailLookupResponse = ref<EmailLookup>({
      aspNetUserId: "",
      firstName: "",
      lastName: "",
      email: "",
      jobTitle: "",
      userExists: false,
    });

    const initialDropDownSetup: DropDownSetup = {
      selectedLicensedDistributor: 0,
      selectedClient: 0,
      selectedFolder: 0,
      selectedPersonalFolder: 0,
    };

    const dropDownSetup = reactive<DropDownSetup>({ ...initialDropDownSetup });
    const languageVersion = ref("english");

    const formData = ref<KTCreateApp>({
      currentAspNetUserId: currentUser.value.id,
      respondentAspNetUserId: "",
      emailSurveyQueId: 0,
      userExists: false,
      surveyEntryType: "",
      languageVersion: "",
      surveyJobTitle: "",
      firstName: "",
      lastName: "",
      email: "",
      jobTitle: "",
      licensedDistributorId: 0,
      clientId: 0,
      folderId: 0,
      personalFolderId: 0,
      autonomyTotal: 0,
      socialTotal: 0,
      patienceTotal: 0,
      certaintyTotal: 0,
      psychicEnergyTotal: 0,
      jobPhraseLineItems: [],
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        createAccountRef.value as HTMLElement
      );

      if (props.userRole === "SuperAdmin") {
        store
          .dispatch(Actions.GET_LICENSED_DISTRIBUTOR_DDL)
          .then(() => {
            //
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText:
                "There was a problem retrieving licensed distributors.",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      } else if (
        props.userRole === "LicensedDistributor" ||
        props.userRole === "LDAssociate"
      ) {
        getClients();
      } else if (
        props.userRole === "PowerUser" ||
        props.userRole === "ClientUser"
      ) {
        getClientFolders();
      }
    });

    const licensedDistributors = computed(() => {
      return store.getters.getLicensedDistributorDDL;
    });

    const clientList = computed(() => {
      return store.getters.getClientDDL;
    });

    const clientFolderList = computed(() => {
      return store.getters.getClientFoldersDDL;
    });

    const personaFolderList = computed(() => {
      return store.getters.getPersonalFoldersDDL;
    });

    store
      .dispatch(Actions.GET_PERSONAL_FOLDERS_DDL, currentUser.value.id)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving personal folders.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const getClients = () => {
      if (props.userRole === "LicensedDistributor") {
        dropDownSetup.selectedLicensedDistributor = props.licensedDistributorId;
      } else if (props.userRole === "LDAssociate") {
        dropDownSetup.selectedLicensedDistributor = props.licensedDistAssocId;
      }

      store
        .dispatch(
          Actions.GET_CLIENTS_DDL,
          toRaw(dropDownSetup.selectedLicensedDistributor)
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem retrieving clients.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const getClientFolders = () => {
      if (props.userRole === "PowerUser" || props.userRole === "ClientUser") {
        dropDownSetup.selectedClient = props.clientId;
      }

      store
        .dispatch(
          Actions.GET_CLIENT_JOB_FOLDERS_DDL,
          toRaw(dropDownSetup.selectedClient)
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem retrieving client folders.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const createAppSchema = [
      Yup.object({
        surveyJobTitle: Yup.string().required().label("Job Title"),
        firstName: Yup.string().required().label("First Name"),
        lastName: Yup.string().required().label("Last Name"),
        email: Yup.string().required().email().label("Email"),
        jobTitle: Yup.string().required().label("Job Title"),
      }),
      Yup.object({}),
    ];

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createAppSchema[currentStepIndex.value];
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2>({
      validationSchema: currentSchema,
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const handleStep = handleSubmit((values) => {
      for (const item in values) {
        // eslint-disable-next-line no-prototype-builtins
        if (values.hasOwnProperty(item)) {
          if (values[item]) {
            formData.value[item] = values[item];
          }
        }
      }

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const formSubmit = () => {
      formData.value.surveyEntryType = props.entryType;
      formData.value.languageVersion = languageVersion.value;
      formData.value.folderId = dropDownSetup.selectedFolder;

      if (props.userRole === "PowerUser" || props.userRole === "ClientUser") {
        formData.value.clientId = props.clientId;
      } else {
        formData.value.licensedDistributorId =
          dropDownSetup.selectedLicensedDistributor;
        formData.value.clientId = dropDownSetup.selectedClient;
        formData.value.personalFolderId = dropDownSetup.selectedPersonalFolder;
      }

      if (
        formData.value.folderId === 0 &&
        formData.value.personalFolderId === 0
      ) {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "You must select a folder type.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else {
        store
          .dispatch(Actions.CREATE_JOB_SURVEY, formData.value)
          .then(() => {
            Swal.fire({
              text: "Success. The survey has been created.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Continue",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              resetForm();
              modalRef.value?.click();
              window.location.reload();
            });
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
    };

    resetForm({
      values: {
        ...formData.value,
      },
    });

    function folderChange(val) {
      if (val == "client") {
        dropDownSetup.selectedPersonalFolder = 0;
      } else {
        dropDownSetup.selectedFolder = 0;
      }
    }

    function lookupEmail() {
      store
        .dispatch(Actions.LOOKUP_EMAIL, emailLookupResponse.value)
        .then(() => {
          emailLookupResponse.value = store.getters.getEmailLookupResponse;
          formData.value.email = emailLookupResponse.value.email;

          onEmailLookup();
        });
    }

    function onEmailLookup() {
      if (emailLookupResponse.value.userExists) {
        Swal.fire({
          title: "Duplicate names may exist.",
          html:
            "<b>" +
            emailLookupResponse.value.firstName +
            " " +
            emailLookupResponse.value.lastName +
            "</b><br />" +
            emailLookupResponse.value.email +
            "<br /><br />If this information is correct, click Yes.",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.isConfirmed) {
            formData.value.firstName = emailLookupResponse.value.firstName;
            formData.value.lastName = emailLookupResponse.value.lastName;
            formData.value.jobTitle = emailLookupResponse.value.jobTitle;
            formData.value.respondentAspNetUserId =
              emailLookupResponse.value.aspNetUserId;
            formData.value.userExists = true;
          }
        });
      }
    }

    async function reset() {
      Object.assign(dropDownSetup, initialDropDownSetup);
      resetForm();
    }

    return {
      currentUser,
      createAccountRef,
      totalSteps,
      previousStep,
      handleStep,
      modalRef,
      languageVersion,
      folderChange,
      lookupEmail,
      onEmailLookup,
      emailLookupResponse,
      formSubmit,
      getClients,
      getClientFolders,
      licensedDistributors,
      currentStepIndex,
      formData,
      clientList,
      personaFolderList,
      clientFolderList,
      ...toRefs(dropDownSetup),
      reset,
    };
  },
});
</script>
